import React from 'react';
import { Row, Col } from 'antd';

import { Page, Section, styles } from '../layout/page';
import Headline from '../layout/headline';
import HeadlineSection from '../layout/headline-section';
import Polaroids from '../layout/polaroids';

const Pilots = ({ images }) => (
  <Page>
    <HeadlineSection
      justify='start'
      background='blue'
      title={{ lead: 'Focus on', emphasis: 'Real People' }}
      subtitle={`We all like good stories. We'll help you tell yours.`}
      button={{ label: 'Share Your Story', url: '/contact' }}>
      <p>
        Every food and drink establishment starts with a person and their ideas
        &mdash; a recipe, a cooking method, a new concept &mdash; a story. We
        believe that the inspiration behind great dishes and drinks are the
        human element that help us connect to each other. Our clients' stories,
        and the inspiration behind their hard work is something to embrace.
      </p>
    </HeadlineSection>

    <Section
      color='white'
      className={styles.client}
      itemscope
      itemtype='http://schema.org/Organization'>
      <Row gutter={64} type='flex' justify='start'>
        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
          <Headline
            lead='White Bear Meadery'
            subtitle='Handcrafted Mead'
            background='white'
            type='client'
          />
          <p>
            From humble beginnings,  Josh Eckton and his family  recently opened{' '}
            <strong>Minnesota’s first meadery</strong>. Mead from White Bear
            Meadery is handcrafted, featuring Minnesota-grown honey blended with
            other locally sourced ingredients such as strawberries, raspberries,
            blueberries, apples, and maple syrup.
          </p>
          <p>
            As an ever-gracious host, Josh welcomes newcomers with a smile and
            educates the uninitiated, making personal connections with each
            guest.{' '}
          </p>
          <blockquote>
            Our family is introducing mead (fermented honey) to the masses. As
            we and many other Minnesotans root for the Vikings, or have Viking
            roots, we wanted to open a meadery as a way pay homage to this
            ancient drink’s past.
          </blockquote>
        </Col>
        <Col xs={24} sm={24} md={24} lg={13} xl={13}>
          <Polaroids
            images={[
              {
                src: images.whiteBearMeadery1.childImageSharp.fixed.src,
                title: 'Drink Like a Viking! Drink Mead!',
                height: 200,
              },
              {
                src: images.whiteBearMeadery2.childImageSharp.fixed.src,
                title: 'You Want This Mead',
                height: 270,
              },
            ]}
            padding={`32px 0`}
          />
        </Col>
      </Row>
    </Section>
  </Page>
);

export default Pilots;
