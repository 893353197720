import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Pilots from '../components/pages/pilots';

const seo = {
  title: 'Pilots',
  description: 'Pilots page',
  keywords: ['pilots'],
};

const PilotsPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Pilots images={props.data} />
    </Layout>
  );
};

export default PilotsPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment fixedImageWide on File {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fragment fixedImageTall on File {
    childImageSharp {
      fixed(height: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    whiteBearMeadery1: file(
      relativePath: { eq: "white-bear-meadery-josh.jpg" }
    ) {
      ...fixedImageWide
    }

    whiteBearMeadery2: file(
      relativePath: { eq: "white-bear-meadery-daughter.jpg" }
    ) {
      ...fixedImageTall
    }
  }
`;
